// Toasts

.toast {
  margin-left: auto;
  margin-right: auto;
  border-radius: 0.375rem;
  background-color: rgba(255, 255, 255);
  .toast-header {
    font-weight: 500;
    font-size: 15px;
    border-bottom: 1px solid rgba(219, 218, 222, 0.3);
    .data-feather-wrapper {
      svg {
        width: 18px !important;
        height: 20px !important;
        margin-right: 0.5rem !important;
        margin-bottom: 0.1rem !important;
      }
    }
  }
  &.show {
    z-index: 1030;
  }
  .close:focus {
    outline: none;
  }
}

@include media-breakpoint-down(xs) {
  .toast {
    left: 1rem;
  }
}
