@import "@core/scss/core.scss";
@import "./assets/scss/styles";

.expandedScroll ::-webkit-scrollbar {
  width: 10px;
}

.html {
  scroll-behavior: smooth;
}
